import { createRouter, createWebHistory } from 'vue-router';
import Home from '../App.vue'
import AboutVue from '@/components/AboutVue.vue'
import TokenVue from '../components/TokenVue' ;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'AboutVue',
    component: AboutVue,
  },
  {
    path: '/token',
    name: 'TokenVue',
    component: TokenVue,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  }
});

export default router;