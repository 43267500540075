<template>
<div class="town">
<img class="img" src="../assets/town.png">

</div>
</template>


<style>



.img{
        height: 100%; 
        width: 100%; 
    }


</style>