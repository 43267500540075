<template>
  <div class="app">
  <Appheader></Appheader>
  <Rainvue></Rainvue>
  <Townvue></Townvue>
  <div class="main-content">
    <div>

    </div>
<div id="about">
  <AboutVue></AboutVue>
</div>
<div id="token">
  <Tokenvue></Tokenvue>
</div>
  <Appfooter></Appfooter>
</div>

</div>
</template>


<script>
import Appheader from '@/components/AppHeader.vue'
import Rainvue from '@/components/RainVue.vue'
import Townvue from '@/components/TownVue.vue'
import AboutVue from '@/components/AboutVue.vue'
import Tokenvue from '@/components/TokenVue.vue'
import Appfooter from '@/components/AppFooter.vue'


export default {
  name: 'App',
  components: {
    Appheader,
    Rainvue,
    Townvue,
    AboutVue, 
    Tokenvue, 
    Appfooter
  }

  // Add any data or methods needed here
};
</script>

<style>

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  background: linear-gradient(to bottom, #202020, #05053c);
}

p {
text-align: center ; 
color:white; 
}

</style>